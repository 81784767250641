import React, { useRef } from "react";
import { MenuItem } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import { Hidden, IconButton, Grow, MenuList, Paper } from "@mui/material";
import style from "./Topnav.module.scss";
import { auth } from "../../helper/firebase.helper";
import Logo from "../logocontainers/logocontainer";
import { KeyboardArrowDown } from "@mui/icons-material";
import Account from "../account/Account";
import { Link, useMatch, useNavigate } from "react-router-dom";

const TopNav = ({ showUser, handleSidebar }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);

  const navigate = useNavigate();

  // const login = useMatch("/login/*");
  // const register = useMatch("/register/*");
  const dashboard = useMatch("/dashboard/*");

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <div className={style.navWrapper}>
      <Logo handleSidebar={handleSidebar} />
      {showUser && auth.currentUser && (
        <div className={style.dropdown}>
          <Hidden smDown implementation="css">
            <Account {...{ showUser }} />
          </Hidden>
          <IconButton onClick={handleToggle}>
            <KeyboardArrowDown ref={anchorRef} />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role="button"
            transition
            disablePortal
            placement="bottom-end"
            style={{
              minWidth: "150px",
            }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: "center top" }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {dashboard ? (
                        <MenuItem onClick={() => navigate("/homepage")}>
                          Home
                        </MenuItem>
                      ) : (
                        <MenuItem onClick={() => navigate("/dashboard")}>
                          Dashboard
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => auth.signOut()}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </div>
  );
};

export default TopNav;
