import {
  collection,
  query,
  where,
  getDocs,
  startAfter,
  limit,
  orderBy,
} from "firebase/firestore";
import { auth, database } from "../../../helper/firebase.helper";

const PAGE_SIZE = 10;

export const fetchHistory = async (lastVisible) => {
  const user = auth.currentUser;
  if (user) {
    const historyRef = collection(database, "activities");
    let q = query(
      historyRef,
      // where("client_email", "==", user.email),
      // orderBy("update_time", "desc"),
      limit(PAGE_SIZE)
    );

    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }

    const querySnapshot = await getDocs(q);

    let dataArray = [];
    querySnapshot.forEach((doc) => {
      dataArray.push({ id: doc.id, ...doc.data() });
    });

    if (dataArray.length < PAGE_SIZE) {
      // If the fetched data is less than the page size, it means there are no more pages
      return {
        data: dataArray,
        hasMore: false,
        lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
      };
    }

    return {
      data: dataArray,
      hasMore: true,
      lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
    };
  }
  return { data: [], hasMore: false, lastVisible: null };
};

export const getDate = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  const formattedDate = date.toLocaleString(undefined, optionsDate);

  const formattedDateYear = `${formattedDate} `;

  return formattedDateYear;
};

export const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return "#4dd74d";
    case 0:
      return "#f4db05";
    case 2:
      return "red";
    default:
      return "#0884ff";
  }
};
