import firebaseApp from "firebase/compat/app";
import "firebase/compat/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyCzZwd2HdfjlrTbdeom3cx2bvmMf09-E7g",
  authDomain: "detalks-test-23af7.firebaseapp.com",
  projectId: "detalks-test-23af7",
  storageBucket: "detalks-test-23af7.appspot.com",
  messagingSenderId: "1061054209051",
  appId: "1:1061054209051:web:5c4be13a906fcd0d6cacbe",
  measurementId: "G-48DBHRR0NH",
};

const app = firebaseApp.initializeApp(firebaseConfig);

const auth = app.auth();
const database = getFirestore(app);
const storage = getStorage(app);
const performance = getPerformance(app);

export { auth, database, performance, storage, app };
