import React, { useEffect, useState } from "react";
import ChooseTherapist from "./ChooseTherapist";
import SelfAssessment from "./SelfAssessment";
import Courses from "./courses/Courses";
import Blogs from "./Blogs";
import { getOrgDetails } from "../../helper/api.helper";
import toast from "react-hot-toast";
import { useStoreState } from "easy-peasy";
import { Helmet } from "react-helmet";

const Home = () => {
  const [data, setData] = useState();
  const user = useStoreState((state) => state.user);

  useEffect(() => {
    if (user) {
      getOrgDetails()
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [user]);
  return (
    <div
      style={{
        padding:
          window.innerWidth > 600 ? "var(--space-lg)" : "var(--space-md)",
      }}
      className="wrapper"
    >
      <Helmet>
        <title>Home - DeTalks</title>
      </Helmet>
      <div className="institute-details"></div>
      <ChooseTherapist />
      <SelfAssessment assessments={data?.selfAssessments} />
      <Courses courses={data?.courses} />
      <Blogs />
      <style jsx>
        {`
          // .wrapper {
          //   max-width: 1200px;
          // }
          .institute-details {
            width: 100%;
            height: 200px;
            border: 1px solid black;
          }
        `}
      </style>
    </div>
  );
};

export default Home;
