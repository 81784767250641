import React, { Suspense, useEffect } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage/Homepage";
import "react-calendar/dist/Calendar.css";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./components/loader/Loader";
import { useStoreState, useStoreActions } from "easy-peasy";
import { auth } from "./helper/firebase.helper";
import RouteGuard from "./components/RouteGuard";
import { getIdTokenResult, onAuthStateChanged, signOut } from "firebase/auth";
import Dashboard from "./pages/dashboard/Dashboard";

function App() {
  const setUser = useStoreActions((actions) => actions.setUser);
  const user = useStoreState((state) => state.user);
  const isUserAuthenticated = user;

  useEffect(() => {
    onAuthStateChanged(auth, async (usr) => {
      if (usr) {
        getIdTokenResult(usr, true).then((res) => {
          if (res.claims?.userType === 0) {
            setUser({
              ...usr,
              orgId: res.claims.orgId,
              userType: res.claims.userType
            })
          } else {
            toast.error('Please use a client account to sign in');
            signOut(auth);
          }
        })
      } else {
        setUser(usr);
      }
    });
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      {
        user === false ? <Loader /> : <Routes>
        <Route index element={<Navigate to="homepage" replace />} />
        <Route
          path="/login/*"
          element={
            <RouteGuard
              isAuthenticated={!isUserAuthenticated}
              redPath="/homepage"
              component={Login}
            />
          }
        />
        <Route path="/register/*" element={<Register />} />
        <Route path="/homepage/*" element={<Homepage />} />
        <Route
          path="/dashboard/*"
          element={
            <RouteGuard
              isAuthenticated={isUserAuthenticated}
              redPath="/login"
              component={Dashboard}
            />
          }
        />
      </Routes>
      }
      <Toaster position="bottom-center" />
    </Suspense>
  );
}

export default App;
