import pdf1 from "../../src/assets/pdf1.pdf";
import pdf2 from "../../src/assets/pdf2.pdf";
import pdf3 from "../../src/assets/pdf3.pdf";

export const therapists = [
  {
    id: 1,
    name: "John Doe",
    photo:
      "https://media.istockphoto.com/id/1326420590/photo/portrait-of-happy-black-male-psychologist-looking-at-camera-and-taking-notes-during-therapy.jpg?s=612x612&w=0&k=20&c=sQIUcX108sC9tH4CG9Vh4RYjthJ5bJpUFfdcGcdKiM4=",

    location: "Ludhiana, Punjab.",
    title: "Clinical Psychologist",
    languages: "English, Hindi, Punjabi",
    worksWith: "Children, Adolescents, Adults",
    specialties:
      " ADD/ADHD, Anger Management, Anxiety, Depression, OCD, Parenting",
    exp: "10+ years",
    license: "RCI A2024",
    qualification:
      "M.Phil. Clinical Psychology, M.A. Psychology, P.G.D Counselling Psychology",
    modalities:
      "Cognitive Behavioral Therapy, Solutions Focussed Brief Therapy, Trauma Informed Care",
    contactType: "Telephonic, Video Call",
    address:
      "Across Chemistry Department, Old Hobbies Club, Indian Institute of Technology Roorkee, Roorkee, Uttarakhand, 247667 ",
    phone: "01332 - 284372",
  },
  {
    id: 2,
    name: "Mridula Sharma",
    photo:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAjKonYsE1V9VwWSGfw9t-ePnicaF0SNBlaJtFyNI7R7795sosUErWD832D5CTMQbQhS4&usqp=CAU",

    location: "Ludhiana, Punjab.",
    title: "Clinical Psychologist",
    languages: "English, Hindi, Punjabi",
    worksWith: "Children, Adolescents, Adults",
    specialties:
      " ADD/ADHD, Anger Management, Anxiety, Depression, OCD, Parenting",
    exp: "10+ years",
    license: "RCI A2024",
    qualification:
      "M.Phil. Clinical Psychology, M.A. Psychology, P.G.D Counselling Psychology",
    modalities:
      "Cognitive Behavioral Therapy, Solutions Focussed Brief Therapy, Trauma Informed Care",
    contactType: "Telephonic, Video Call",
    address:
      "Across Chemistry Department, Old Hobbies Club, Indian Institute of Technology Roorkee, Roorkee, Uttarakhand, 247667 ",
    phone: "01332 - 284372",
  },
  {
    id: 3,
    name: "John Doe",
    photo:
      "https://media.istockphoto.com/id/1326420590/photo/portrait-of-happy-black-male-psychologist-looking-at-camera-and-taking-notes-during-therapy.jpg?s=612x612&w=0&k=20&c=sQIUcX108sC9tH4CG9Vh4RYjthJ5bJpUFfdcGcdKiM4=",

    location: "Ludhiana, Punjab.",
    title: "Clinical Psychologist",
    languages: "English, Hindi, Punjabi",
    worksWith: "Children, Adolescents, Adults",
    specialties:
      " ADD/ADHD, Anger Management, Anxiety, Depression, OCD, Parenting",
    exp: "10+ years",
    license: "RCI A2024",
    qualification:
      "M.Phil. Clinical Psychology, M.A. Psychology, P.G.D Counselling Psychology",
    modalities:
      "Cognitive Behavioral Therapy, Solutions Focussed Brief Therapy, Trauma Informed Care",
    contactType: "Telephonic, Video Call",
    address:
      "Across Chemistry Department, Old Hobbies Club, Indian Institute of Technology Roorkee, Roorkee, Uttarakhand, 247667 ",
    phone: "01332 - 284372",
  },
  {
    id: 4,
    name: "Mridula Sharma",
    photo:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAjKonYsE1V9VwWSGfw9t-ePnicaF0SNBlaJtFyNI7R7795sosUErWD832D5CTMQbQhS4&usqp=CAU",

    location: "Model Gram, Ludhiana, Punjab.",
    title: "Clinical Psychologist",
    languages: "English, Hindi, Punjabi",
    worksWith: "Children, Adolescents, Adults",
    specialties:
      " ADD/ADHD, Anger Management, Anxiety, Depression, OCD, Parenting, etc",
    exp: "10+ years",
    license: "RCI A2024",
    qualification:
      "M.Phil. Clinical Psychology, M.A. Psychology, P.G.D Counselling Psychology",
    modalities:
      "Cognitive Behavioral Therapy, Solutions Focussed Brief Therapy, Trauma Informed Care",
    contactType: "Telephonic, Video Call",
    address:
      "Across Chemistry Department, Old Hobbies Club, Indian Institute of Technology Roorkee, Roorkee, Uttarakhand, 247667 ",
    phone: "01332 - 284372",
  },
  {
    id: 5,
    name: "John Doe",
    photo:
      "https://media.istockphoto.com/id/1326420590/photo/portrait-of-happy-black-male-psychologist-looking-at-camera-and-taking-notes-during-therapy.jpg?s=612x612&w=0&k=20&c=sQIUcX108sC9tH4CG9Vh4RYjthJ5bJpUFfdcGcdKiM4=",

    location: "Ludhiana, Punjab.",
    title: "Clinical Psychologist",
    languages: "English, Hindi, Punjabi",
    worksWith: "Children, Adolescents, Adults",
    specialties:
      " ADD/ADHD, Anger Management, Anxiety, Depression, OCD, Parenting",
    exp: "10+ years",
    license: "RCI A2024",
    qualification:
      "M.Phil. Clinical Psychology, M.A. Psychology, P.G.D Counselling Psychology",
    modalities:
      "Cognitive Behavioral Therapy, Solutions Focussed Brief Therapy, Trauma Informed Care",
    contactType: "Telephonic, Video Call",
    address:
      "Across Chemistry Department, Old Hobbies Club, Indian Institute of Technology Roorkee, Roorkee, Uttarakhand, 247667 ",
    phone: "01332 - 284372",
  },
  {
    id: 6,
    name: "Mridula Sharma",
    photo:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAjKonYsE1V9VwWSGfw9t-ePnicaF0SNBlaJtFyNI7R7795sosUErWD832D5CTMQbQhS4&usqp=CAU",

    location: "Ludhiana, Punjab.",
    title: "Clinical Psychologist",
    languages: "English, Hindi, Punjabi",
    worksWith: "Children, Adolescents, Adults",
    specialties:
      " ADD/ADHD, Anger Management, Anxiety, Depression, OCD, Parenting",
    exp: "10+ years",
    license: "RCI A2024",
    qualification:
      "M.Phil. Clinical Psychology, M.A. Psychology, P.G.D Counselling Psychology",
    modalities:
      "Cognitive Behavioral Therapy, Solutions Focussed Brief Therapy, Trauma Informed Care",
    contactType: "Telephonic, Video Call",
    address:
      "Across Chemistry Department, Old Hobbies Club, Indian Institute of Technology Roorkee, Roorkee, Uttarakhand, 247667 ",
    phone: "01332 - 284372",
  },
  {
    id: 7,
    name: "John Doe",
    photo:
      "https://media.istockphoto.com/id/1326420590/photo/portrait-of-happy-black-male-psychologist-looking-at-camera-and-taking-notes-during-therapy.jpg?s=612x612&w=0&k=20&c=sQIUcX108sC9tH4CG9Vh4RYjthJ5bJpUFfdcGcdKiM4=",

    location: "Ludhiana, Punjab.",
    title: "Clinical Psychologist",
    languages: "English, Hindi, Punjabi",
    worksWith: "Children, Adolescents, Adults",
    specialties:
      " ADD/ADHD, Anger Management, Anxiety, Depression, OCD, Parenting",
    exp: "10+ years",
    license: "RCI A2024",
    qualification:
      "M.Phil. Clinical Psychology, M.A. Psychology, P.G.D Counselling Psychology",
    modalities:
      "Cognitive Behavioral Therapy, Solutions Focussed Brief Therapy, Trauma Informed Care",
    contactType: "Telephonic, Video Call",
    address:
      "Across Chemistry Department, Old Hobbies Club, Indian Institute of Technology Roorkee, Roorkee, Uttarakhand, 247667 ",
    phone: "01332 - 284372",
  },
  {
    id: 8,
    name: "Mridula Sharma",
    photo:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAjKonYsE1V9VwWSGfw9t-ePnicaF0SNBlaJtFyNI7R7795sosUErWD832D5CTMQbQhS4&usqp=CAU",

    location: "Model Gram, Ludhiana, Punjab.",
    title: "Clinical Psychologist",
    languages: "English, Hindi, Punjabi",
    worksWith: "Children, Adolescents, Adults",
    specialties:
      " ADD/ADHD, Anger Management, Anxiety, Depression, OCD, Parenting, etc",
    exp: "10+ years",
    license: "RCI A2024",
    qualification:
      "M.Phil. Clinical Psychology, M.A. Psychology, P.G.D Counselling Psychology",
    modalities:
      "Cognitive Behavioral Therapy, Solutions Focussed Brief Therapy, Trauma Informed Care",
    contactType: "Telephonic, Video Call",
    address:
      "Across Chemistry Department, Old Hobbies Club, Indian Institute of Technology Roorkee, Roorkee, Uttarakhand, 247667 ",
    phone: "01332 - 284372",
  },
];

export const timeSlots = [
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
];

export const eventTypes = [
  "Individual Therapy - 45 min",
  "Couple Counseling - 60 min",
  "Career Counseling - 90 min",
];

export const locations = ["Telehealth ", "In-person : ", "In-person: "];

export const assessments = [
  {
    title: "Depression",
    image:
      "https://wallpapers.com/images/hd/depression-girl-pictures-3aybt7ql965uw0v1.jpg",
    duration: "5 to 10 minutes",
    ageGroup: "18 years and above",
    id: 1,
  },
  {
    title: "Depression",
    image:
      "https://wallpapers.com/images/hd/depression-girl-pictures-3aybt7ql965uw0v1.jpg",
    duration: "5 to 10 minutes",
    ageGroup: "18 years and above",
    id: 2,
  },
  {
    title: "Depression",
    image:
      "https://wallpapers.com/images/hd/depression-girl-pictures-3aybt7ql965uw0v1.jpg",
    duration: "5 to 10 minutes",
    ageGroup: "18 years and above",
    id: 3,
  },
];

export const courses = [
  {
    title: "Stress Mastery: Transforming Pressure into Power",
    subTitle: "Big Five Personality traits",
    image: "https://s3.envato.com/files/236639165/preview.jpg",
    points: [
      "Decode Stress",
      "Spot Your Stressors",
      "Redefine Your Reaction",
      "Techniques Unleashed",
    ],
    professorPics: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSExIJT3xI3BmkKXtxbDD6z0SdKoh5LOAARdQ&s",
    ],
    duration: "20 min",
    id: 1,
    pdfUrl: "https://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
  },
  {
    title: "Ignite your Resilience: Join the Revolution",
    subTitle: "Big Five Personality traits",

    image:
      "https://cdn.shopify.com/s/files/1/0112/0990/0091/t/3/assets/adobestock_159472423-1654798367118.jpeg?v=1654798369",
    points: [
      "Embrace Adversity",
      "Mindset Mastery",
      "Bounce Back Strategies",
      "Connection is Key",
    ],
    professorPics: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlGy_p1OxCKPPo44Yry-6sXjEfIXkbblUzCQ&s",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSExIJT3xI3BmkKXtxbDD6z0SdKoh5LOAARdQ&s",
    ],
    duration: "15 min",
    id: 2,
    pdfUrl: pdf2,
  },
  {
    title: "Compassion Catalyst: Cultivating Kindness within",
    subTitle: "Big Five Personality traits",

    image: "https://fwcai.com/wp-content/uploads/2023/06/therapy-1-1.png",
    points: [
      "Heart-Centered Living",
      "Self-Compassion Mastery",
      "Empathy Unleashed",
      "Acts of Kindness",
    ],
    duration: "20 min",
    professorPics: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSExIJT3xI3BmkKXtxbDD6z0SdKoh5LOAARdQ&s",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlGy_p1OxCKPPo44Yry-6sXjEfIXkbblUzCQ&s",
    ],
    id: 3,
    pdfUrl: pdf3,
  },
];

export const blogs = [
  {
    title: "10 Breathing Exercises that can help ease Anxiety",
    image:
      "https://t3.ftcdn.net/jpg/06/43/07/12/360_F_643071289_I60pgWvCavlK0pswqkibpeYudr0HY9Ft.jpg",

    professorPics: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSExIJT3xI3BmkKXtxbDD6z0SdKoh5LOAARdQ&s",
    ],
    duration: "5 min",
    description:
      " If you are a yoga enthusiast, you might practice breathing during your yoga sessions. You may have heard the phrase 'practice breathe exercises' in the event that you had to see your therapist or your doctor. If you're under stress, you could have done it...",
    id: 1,
  },
  {
    title: "10 Breathing Exercises that can help ease Anxiety",
    image:
      "https://t3.ftcdn.net/jpg/06/43/07/12/360_F_643071289_I60pgWvCavlK0pswqkibpeYudr0HY9Ft.jpg",

    professorPics: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlGy_p1OxCKPPo44Yry-6sXjEfIXkbblUzCQ&s",
    ],
    duration: "5 min",
    description:
      " If you are a yoga enthusiast, you might practice breathing during your yoga sessions. You may have heard the phrase 'practice breathe exercises' in the event that you had to see your therapist or your doctor. If you're under stress, you could have done it...",
    id: 2,
  },
  {
    title: "10 Breathing Exercises that can help ease Anxiety",
    image:
      "https://t3.ftcdn.net/jpg/06/43/07/12/360_F_643071289_I60pgWvCavlK0pswqkibpeYudr0HY9Ft.jpg",

    duration: "5 min",
    professorPics: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSExIJT3xI3BmkKXtxbDD6z0SdKoh5LOAARdQ&s",
    ],
    description:
      " If you are a yoga enthusiast, you might practice breathing during your yoga sessions. You may have heard the phrase 'practice breathe exercises' in the event that you had to see your therapist or your doctor. If you're under stress, you could have done it...",
    id: 3,
  },
];

export const history = [
  {
    id: 1,
    email: "abc@abc.com",
    type: 0,
    activityTitle: "IPIP NEO 120",
    completion_time: 1720017842215,
    status: "Submitted",
    patternType: "PLEASANT TYPE",
  },
  {
    id: 2,
    email: "abc@abc.com",
    type: 0,
    activityTitle: "IPIP NEO 120",
    completion_time: 1720017842215,
    status: "Initiated",
  },
  {
    id: 3,
    email: "abc@abc.com",
    type: 0,
    activityTitle: "IPIP NEO 120",
    completion_time: 1720017842215,
    status: "Expired",
  },
  {
    id: 4,
    email: "abc@abc.com",
    type: 1,
    activityTitle: "Stress Management",
    completion_time: 1720017842215,
    status: "Submitted",
    score: "8/10",
  },
];

export const quiz = [
  {
    question: "What is the capital of France?",
    options: ["Berlin", "Madrid", "Paris", "Lisbon"],
    correctAnswer: "Paris",
  },
  {
    question: "Which planet is known as the Red Planet?",
    options: ["Earth", "Mars", "Jupiter", "Saturn"],
    correctAnswer: "Mars",
  },
  {
    question: "Who wrote 'To Kill a Mockingbird'?",
    options: [
      "Harper Lee",
      "Mark Twain",
      "Ernest Hemingway",
      "F. Scott Fitzgerald",
    ],
    correctAnswer: "Harper Lee",
  },
  {
    question: "What is the largest ocean on Earth?",
    options: [
      "Atlantic Ocean",
      "Indian Ocean",
      "Arctic Ocean",
      "Pacific Ocean",
    ],
    correctAnswer: "Pacific Ocean",
  },
  {
    question: "What is the smallest unit of matter?",
    options: ["Atom", "Molecule", "Electron", "Proton"],
    correctAnswer: "Atom",
  },
  {
    question: "In which year did the Titanic sink?",
    options: ["1912", "1905", "1923", "1915"],
    correctAnswer: "1912",
  },
  {
    question: "What is the chemical symbol for gold?",
    options: ["Au", "Ag", "Fe", "Pb"],
    correctAnswer: "Au",
  },
  {
    question: "Who painted the Mona Lisa?",
    options: [
      "Leonardo da Vinci",
      "Vincent van Gogh",
      "Pablo Picasso",
      "Claude Monet",
    ],
    correctAnswer: "Leonardo da Vinci",
  },
  {
    question: "Which country is known as the Land of the Rising Sun?",
    options: ["China", "Japan", "South Korea", "Thailand"],
    correctAnswer: "Japan",
  },
  {
    question: "What is the hardest natural substance on Earth?",
    options: ["Gold", "Iron", "Diamond", "Platinum"],
    correctAnswer: "Diamond",
  },
];
