import React, { useEffect, useState } from "react";
// import MessagePopup from "./MessagePopup.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
// import { history } from "../../../helper/data.helper.js";
import HistoryItem from "./HistoryItem.js";
import { fetchHistory } from "./history.service.js";

const HistoryList = () => {
  const [history, setHistory] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [popup, setPopup] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const {
      data: newData,
      hasMore: newHasMore,
      lastVisible: newLastVisible,
    } = await fetchHistory(lastVisible);

    if (!newHasMore) {
      setHasMore(false);
    }
    if (newData.length > 0) {
      const updatedHistory = history ? [...history, ...newData] : newData;
      setHistory(updatedHistory);
      setLastVisible(newLastVisible);
    }
  };

  //handle popup open function
  const popupOpen = (message) => {
    setSelectedMessage(message);
    setPopup(!popup);
    setOpen(true);
  };

  //handle popup close function
  const popupClose = () => {
    setSelectedMessage(null);
    setPopup(!popup);
  };

  const toggleDrawer = (value) => () => {
    setOpen(value);
    setPopup(!popup);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={history ? history.length : 0}
        next={fetchData}
        hasMore={hasMore}
        loader={<p className="optional-heading margin-top">Loading...</p>}
        endMessage={
          history && history.length > 0 ? (
            <p className="optional-heading margin-top">That&apos;s All !</p>
          ) : (
            <p className="optional-heading margin-top">No History !</p>
          )
        }
      >
        {history &&
          history.map((data) => (
            <HistoryItem
              key={data.id}
              popupOpen={popupOpen}
              historyItem={data}
            />
          ))}
      </InfiniteScroll>
      {/* {popup && selectedMessage && (
        <MessagePopup
          contact={selectedMessage}
          popupClose={popupClose}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
        />
      )} */}

      <style jsx>
        {`
          .optional-heading {
            text-align: center;
            font-size: 18px;
          }
        `}
      </style>
    </>
  );
};

export default HistoryList;
