import React, { useEffect } from "react";
import Home from "./Home";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TherapistList from "../therapistListing/TherapistList";
import TherapistDetailPage from "../therapistDetail/TherapistDetailPage";
import { useStoreActions, useStoreState } from "easy-peasy";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../helper/firebase.helper";
import RouteGuard from "../../components/RouteGuard";
import TopNav from "../../components/TopNav/TopNav";
import Footer from "../../components/Footer";
import Course from "./courses/course/Course";

const Homepage = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0884ff",
      },
      secondary: {
        main: "#73768c",
      },
    },
    typography: {
      button: {
        textTransform: "capitalize",
      },
    },
  });

  const user = useStoreState((state) => state.user);
  const isUserAuthenticated = user;

  return (
    <ThemeProvider theme={theme}>
      <TopNav showUser />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/therapist-list" element={<TherapistList />} /> */}
        {/* <Route path="/therapist/:id" element={<TherapistDetailPage />} /> */}
        <Route
          path="/therapist-list"
          element={
            <RouteGuard
              isAuthenticated={isUserAuthenticated}
              redPath="/login"
              component={TherapistList}
            />
          }
        />
        <Route
          path="/therapist/:id"
          element={
            <RouteGuard
              isAuthenticated={isUserAuthenticated}
              redPath="/login"
              component={TherapistDetailPage}
            />
          }
        />
        {/* <Route
          path="/course-list"
          element={
            <RouteGuard
              isAuthenticated={isUserAuthenticated}
              redPath="/login"
              component={}
            />
          }
        /> */}
        <Route
          path="/course/:id"
          element={
            <RouteGuard
              isAuthenticated={isUserAuthenticated}
              redPath="/login"
              component={Course}
            />
          }
        />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default Homepage;
