import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfPage = ({ selectedCourse, setVisitedPages }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const updateScale = () => {
    const width = window.innerWidth;

    if (width <= 375) {
      setScale(0.4);
    } else if (width <= 430) {
      setScale(0.5);
    } else if (width <= 480) {
      setScale(0.6);
    } else if (width <= 540) {
      setScale(0.7);
    } else if (width <= 600) {
      setScale(0.8);
    } else if (width <= 685) {
      setScale(0.7);
    } else if (width <= 760) {
      setScale(0.8);
    } else if (width <= 820) {
      setScale(0.9);
    } else {
      setScale(1);
    }
  };

  useEffect(() => {
    setVisitedPages((prevState) => ({
      ...prevState,
      pdf: true,
    }));
  }, []);

  useEffect(() => {
    updateScale();
    window.addEventListener("resize", updateScale);

    return () => {
      window.removeEventListener("resize", updateScale);
    };
  }, [scale]);

  const handleDownload = async () => {
    // const link = document.createElement("a");
    // link.target = "_blank";
    // link.href = selectedCourse.pdfUrl;
    // link.download = "File.pdf";
    // link.click();

    try {
      const response = await fetch(selectedCourse.pdfUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const title = selectedCourse.title.substring(0, 30).replace(/\s+/g, "_");
      const filename = `${title}.pdf`;

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
    <div className="pdf-wrapper">
      <h4>{selectedCourse.subTitle}</h4>
      {selectedCourse && selectedCourse.course.resourceUrl && (
        <div className="pdf-viewer margin-top--lg flex-container direction-column ">
          <Document
            file={selectedCourse.course.resourceUrl}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} className="pdf-page" scale={scale} />
          </Document>
          <div style={{ textAlign: "center" }}>
            <IconButton
              disabled={pageNumber <= 1}
              onClick={previousPage}
              color="primary"
            >
              <ArrowBack />
            </IconButton>
            <span>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </span>
            <IconButton
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              color="primary"
            >
              <ArrowForward />
            </IconButton>
          </div>
        </div>
      )}
      <Button
        style={{ marginTop: "20px", textTransform: "none" }}
        endIcon={<FileDownloadOutlinedIcon color="primary" />}
        onClick={handleDownload}
      >
        File.pdf
      </Button>
      <style jsx>
        {`
          .pdf-wrapper {
            padding: 0px 40px;
          }
          .pdf-viewer {
            border: 1px solid lightgrey;
            padding: 10px 0px;

            width: 100%;
            height: auto;
            overflow: scroll;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
          .pdf-page {
            width: 100%;
            height: auto;
          }

          span {
            margin: 0px 10px;
          }
        `}
      </style>
    </div>
  );
};

export default PdfPage;
