import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { quizSubmit } from "../../../../helper/api.helper";
import Loader from "../../../../components/loader/Loader";

const QuizPage = ({ setVisitedPages, selectedCourse }) => {
  console.log(selectedCourse.course.quiz);
  const [isLoading, setLoading] = useState(false);
  const [quiz, setQuiz] = useState([]);
  const [answers, setAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState({});

  useEffect(() => {
    setQuiz(selectedCourse.course.quiz);
  }, []);

  const handleOptionChange = (questionIndex, selectedOption) => {
    const queNo = questionIndex + 1;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [queNo]: selectedOption,
    }));
  };

  const handleQuizSubmit = async () => {
    setLoading(true);
    const allAnswered = await quiz.every((_, index) => answers[index + 1]);

    if (!allAnswered) {
      console.log(answers);
      setLoading(false);
      setAttemptedSubmit(true);
      return;
    }

    quizSubmit(selectedCourse.courseActivity.id, { responses: answers })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          setCorrectAnswers(
            res.data.answers.reduce((acc, answer) => {
              acc[answer.question] = answer.ans;
              return acc;
            }, {})
          );
          console.log(res.data);
          setAttemptedSubmit(false);
          setLoading(false);
          setIsSubmitted(true);
          setVisitedPages((prevState) => ({
            ...prevState,
            quiz: true,
          }));
          toast.success("Submitted successfully");
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });

    console.log(answers);
  };

  return (
    <div className="quiz-wrapper">
      {isLoading ? <Loader /> : null}
      <h4>Quiz 1/1</h4>
      {quiz.map((question, index) => (
        <div key={index}>
          <h4 style={{ fontWeight: "normal" }}>{`${index + 1}. ${
            question.question
          }`}</h4>
          <FormControl
            fullWidth
            component="fieldset"
            error={attemptedSubmit && !answers[index + 1]}
          >
            <RadioGroup
              aria-label={`question-${index}`}
              name={`question-${index}`}
              value={answers[index + 1] || ""}
              onChange={(e) => handleOptionChange(index, e.target.value)}
            >
              {question.options.map((option, optionIndex) => {
                const isCorrect =
                  isSubmitted && correctAnswers[question.question] === option;
                const isSelected = answers[index + 1] === option;
                return (
                  <FormControlLabel
                    key={optionIndex}
                    value={option}
                    control={<Radio />}
                    label={option}
                    sx={{
                      maxHeight: "35px",
                      border:
                        isSubmitted && isCorrect ? "1px solid green" : "none",
                      backgroundColor:
                        isSubmitted && isCorrect
                          ? "#f2fff2"
                          : // : isSubmitted && isSelected && !isCorrect
                            // ? "lightcoral"
                            "transparent",
                      borderRadius: "5px",
                      margin: "0px",
                      // padding: "0px 10px 0px 0px",
                    }}
                  />
                );
              })}
            </RadioGroup>
            {attemptedSubmit && !answers[index + 1] && (
              <FormHelperText>
                Please select an option for this question.
              </FormHelperText>
            )}
          </FormControl>
        </div>
      ))}

      <div className="flex-container">
        {selectedCourse.courseActivity.score !== undefined ? (
          <b className="margin-top--md">
            Score - {selectedCourse.courseActivity.score}
          </b>
        ) : (
          <Button
            sx={{ maxHeight: "30px", marginTop: "20px", textTransform: "none" }}
            variant="contained"
            onClick={handleQuizSubmit}
          >
            Submit
          </Button>
        )}
        {attemptedSubmit && (
          <p style={{ color: "#d32f2f", margin: "22px 0px 0px 10px" }}>
            Please complete all the questions.
          </p>
        )}
      </div>
      <style jsx>{`
        .quiz-wrapper {
          max-height: 90vh;
          overflow: scroll;
          padding: 0px 40px 20px 40px;
        }
        b {
          padding: 5px 10px;
          border: 1px solid #0884ff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
        }
        @media only screen and (max-width: 600px) {
          .quiz-wrapper {
            padding: 0px 20px 20px 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default QuizPage;
