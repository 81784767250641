import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import RecommendOutlinedIcon from "@mui/icons-material/RecommendOutlined";
import { initSelfAssessment } from "../../helper/api.helper";
import toast from "react-hot-toast";
import { useStoreState } from "easy-peasy";

const SelfAssessment = ({ assessments = [] }) => {
  const user = useStoreState((state) => state.user);
  const navigate = useNavigate();
  const initAssessment = (assessmentId) => {
    if (user) {
      initSelfAssessment(assessmentId)
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            window.location.href = res.assessmentUrl;
          }
        });
    } else {
      toast.error("Please login to access assessments");
      navigate("/login");
    }
  };
  return (
    <div className="margin-top--xl">
      <h2 style={{ fontWeight: "normal", marginLeft: "2%" }}>
        SELF-ASSESSMENT
      </h2>
      <h2 className="margin-top--xs" style={{ marginLeft: "2%" }}>
        Not Sure ? Take a Mental Health Test
      </h2>
      <p style={{ color: "#611616", marginLeft: "2%" }}>
        Online screening is one of the quickest and easiest ways to determine
        whether you are experiencing symptoms of a mental health condition and
        if you should seek help. Use our confidential self-assessment tools to
        evaluate your mental health and receive personalized feedback on your
        emotional and psychological well-being.
      </p>
      <div className="flex-container  list-div">
        {assessments.slice(0, 3).map((assessment, index) => (
          <div
            key={assessment.assessment_id}
            onClick={() => initAssessment(assessment.assessment_id)}
            className="assessment-div margin-top--lg"
          >
            <div className="image-div">
              <img alt="assessment-pic" src={assessment.logo} height={150} />
            </div>
            <div className="assessment-details">
              <h3 style={{ marginTop: "-10px" }}>
                <span
                  style={{
                    borderBottom: "1px solid lightgrey",
                    paddingBottom: "10px",
                    color: "black",
                  }}
                >
                  {assessment.name}
                </span>
              </h3>
              <div className="flex-container margin-top--lg">
                <RecommendOutlinedIcon fontSize="small" color="primary" />
                <p style={{ margin: "-1px 0px 0px 5px" }}>
                  {assessment.eligibility}
                </p>
              </div>
              <div className="flex-container margin-top--sm">
                <HourglassEmptyIcon fontSize="small" color="primary" />
                <p style={{ margin: "-1px 0px 0px 5px" }}>
                  {assessment.duration}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Button
        // href=""
        variant="contained"
        color="primary"
        // size="small"
        //   onClick={}
        sx={{ textTransform: "none", marginTop: "20px", marginLeft: "2%" }}
      >
        Browse all assessments
      </Button>
      <style jsx>{`
        .list-div {
          flex-wrap: wrap;
          justify-content: left;
        }
        .assessment-div {
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          min-width: 280px;
          max-width: 300px;
          overflow: hidden;
          margin-left: 2%;
        }
        .assessment-div:hover {
          background-color: #f4f4f4;
        }

        .image-div img {
          object-fit: cover;
        }
        .assessment-details {
          padding: 20px;
        }
      `}</style>
    </div>
  );
};

export default SelfAssessment;
