import OTPInput from "react-otp-input";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../../helper/firebase.helper";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  linkWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import Loader from "../../../components/loader/Loader";
import { Helmet } from "react-helmet";

const RegisterNumber = () => {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);

  const navigate = useNavigate();

  function setupRecaptcha() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            handleSignIn();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  function handleSignIn() {
    setLoading(true);
    setupRecaptcha();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + ph;
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent successfully!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("OTP sending error : " + error.message);
        console.log(error.message);
      });
  }

  function verifyOtp() {
    setLoading(true);
    const verificationId = confirmationResult.verificationId;
    const credential = PhoneAuthProvider.credential(verificationId, otp);

    linkWithCredential(auth.currentUser, credential)
      .then((usercred) => {
        setLoading(false);
        toast.success("Phone number linked successfully");
        navigate("/homepage");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Phone number linking error: " + error.message);
        console.log(error.message);
      });
  }

  return (
    <div className="phone-wrapper">
      <Helmet>
        <title>Sign up - DeTalks</title>
      </Helmet>
      <div id="recaptcha-container"></div>
      {loading ? <Loader /> : null}
      <div className="phone-div">
        <h3>Verify your phone number</h3>

        {showOTP ? (
          <>
            <p style={{ margin: "25px 0px" }}>Enter your OTP</p>
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              isInputNum
              shouldAutoFocus
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: "1.5rem",
                height: "1.5rem",
                margin: "0 0.5rem",
                fontSize: "1.2rem",
                borderRadius: "4px",
                border: "1px solid #0884ff",
              }}
            />
            <Button
              sx={{ marginTop: "30px" }}
              onClick={verifyOtp}
              variant="contained"
              // size="large"
              color="primary"
            >
              <span>Verify OTP</span>
            </Button>
          </>
        ) : (
          <>
            <p style={{ margin: "25px 0px" }}>Enter phone number</p>
            <PhoneInput country={"in"} value={ph} onChange={setPh} />
            <Button
              sx={{ marginTop: "30px" }}
              onClick={handleSignIn}
              variant="contained"
              // size="large"
              color="primary"
            >
              Send OTP
            </Button>
          </>
        )}
      </div>
      <Button
        sx={{
          marginTop: "20px",
          marginLeft: "2%",
          fontSize: "var(--text-sm)",
        }}
        variant="outlined"
        onClick={() => {
          // toast.success("Skipping phone number verification");
          navigate("/login");
        }}
        color="primary"
      >
        Skip
      </Button>
      <style jsx>{`
        .phone-wrapper {
          max-width: 360px;
          margin: auto;
          margin-top: 100px;
          min-height: 80vh;
        }
        .phone-div {
          margin: 0px 5px;
          padding: 20px;
          border: 1px solid #0884ff;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        @media only screen and (max-width: 350px) {
          .phone-div {
            padding: 20px 10px 30px 10px;
          }
        }
      `}</style>
    </div>
  );
};

export default RegisterNumber;
