import { Button } from "@mui/material";
import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initCourseAssessment } from "../../../../helper/api.helper";
import toast from "react-hot-toast";
import Loader from "../../../../components/loader/Loader";

const Assessment = ({ selectedCourse, setVisitedPages }) => {
  const [isLoading, setLoading] = useState(false);

  const user = useStoreState((state) => state.user);
  const navigate = useNavigate();
  function startAssessment() {
    setLoading(true);
    if (user) {
      initCourseAssessment(selectedCourse.courseActivity.id)
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            setLoading(false);
            window.location.href = res.assessmentUrl;
          } else {
            setLoading(false);
            toast.error(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
        });
    } else {
      setLoading(false);
      toast.error("Failed to initiate assessment");
    }
  }

  useEffect(() => {
    if (selectedCourse.courseActivity.assessment_completion_time) {
      setVisitedPages((prevState) => ({
        ...prevState,
        assessment: true,
      }));
    }
  }, []);

  return (
    <div className="assessment-wrapper">
      {isLoading ? <Loader /> : null}
      <h4>{selectedCourse.subTitle}</h4>

      <p className="margin-top--md">{selectedCourse.course.desc}</p>

      {selectedCourse.courseActivity.report_url ? (
        <Button
          href={selectedCourse.courseActivity.report_url}
          target="_blank"
          variant="contained"
          color="primary"
          sx={{ maxHeight: "30px", margin: "20px 0px" }}
        >
          View report
        </Button>
      ) : selectedCourse.courseActivity.assessment_url ? (
        <Button
          href={selectedCourse.courseActivity.assessment_url}
          variant="contained"
          color="primary"
          sx={{ maxHeight: "30px", margin: "20px 0px" }}
        >
          Continue assessment
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          sx={{ maxHeight: "30px", margin: "20px 0px" }}
          onClick={startAssessment}
        >
          Start assessment
        </Button>
      )}

      <style jsx>
        {`
          .assessment-wrapper {
            padding: 0px 40px;
          }
          @media only screen and (max-width: 600px) {
            .assessment-wrapper {
              padding: 0px 20px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Assessment;
