import { NavLink, useMatch } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import LogoContainer from "../logocontainers/logocontainer";
import styles from "./Sidebar.module.scss";
import clsx from "clsx";

export default function Sidebar({ sidebar, handleSidebar }) {
  const drawerWidth = 240;
  const DrawerHeader = styled(Drawer)({
    width: drawerWidth,
    flexShrink: 0,
    overflow: "auto",
    ".MuiDrawer-paper": {
      width: drawerWidth,
      borderRight: "none",
      top: "auto",
    },
  });

  return (
    <DrawerHeader
      container={document.getElementById("dashboard-container")}
      variant={window.innerWidth > 960 ? "permanent" : "temporary"}
      anchor="left"
      open={sidebar}
      onClose={handleSidebar}
      hideBackdrop
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <div className={styles.sidebar}>
        <div className={styles.top}>
          <LogoContainer handleSidebar={handleSidebar} />
        </div>
        <div className={styles.sidebar_list}>
          <NavLink
            role="link"
            className={({ isActive }) =>
              clsx(styles.single_link, isActive && styles.active_link)
            }
            to="/dashboard/history"
            onClick={window.innerWidth < 960 ? handleSidebar : null}
          >
            <HistoryIcon />
            <div>History</div>
          </NavLink>
        </div>
      </div>
    </DrawerHeader>
  );
}
