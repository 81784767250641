import { Button } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { courses } from "../../../helper/data.helper";
import { useStoreState } from "easy-peasy";
import toast from "react-hot-toast";
import { initCourse } from "../../../helper/api.helper";
import Loader from "../../../components/loader/Loader";

const Courses = ({ courses = [] }) => {
  const [isLoading, setLoading] = useState(false);

  const user = useStoreState((state) => state.user);
  const navigate = useNavigate();
  const startCourse = (courseId) => {
    setLoading(true);
    if (user) {
      initCourse(courseId)
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            setLoading(false);

            navigate(`/homepage/course/${res.data.courseActivity}`);
          } else {
            setLoading(false);

            toast.error(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);

          toast.error(err.message);
        });
    } else {
      setLoading(false);

      toast.error("Please login to access courses");
      navigate("/login");
    }
  };
  return (
    <div className="margin-top--xl">
      {isLoading ? <Loader /> : null}
      <h2 style={{ fontWeight: "normal", marginLeft: "2%" }}>SHORT COURSES</h2>
      <h2 className="margin-top--xs" style={{ marginLeft: "2%" }}>
        Boost Your Well-being with Self-Paced Courses
      </h2>
      <p style={{ color: "#611616", marginLeft: "2%" }}>
        Explore our range of self-paced courses for mental health and personal
        growth. Led by experts, these offerings provide practical tools for
        skill-building, stress management and overall well-being. Earn
        certificates and gain valuable insights at your own pace.
      </p>
      <div className="flex-container  list-div">
        {courses?.slice(0, 3).map((course, index) => (
          <div
            key={course.id}
            className="course-div margin-top--lg"
            onClick={() => startCourse(course.id)}
          >
            {/* <Link
              to={`/homepage/course/${course.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            > */}
            <div className="image-div">
              <img alt="course-pic" src={course.imageUrl} height={150} />
            </div>
            <div className="course-details">
              <h3 style={{ marginTop: "-6px" }}>{course.name}</h3>
              <p>{course.desc}</p>
              {/* <ul>
                {course.points.map((point, index) => (
                  <li key={point}>{point}</li>
                ))}
              </ul> */}
              <div className="flex-container justify-between">
                <div className="flex-container">
                  {/* {course.professorPics.map((pic, index) => (
                    <img key={pic} src={pic} height={35} alt="professor-pic" />
                  ))} */}
                </div>
                <b className="duration-tag">{course.duration} min</b>
              </div>
            </div>
            {/* </Link> */}
          </div>
        ))}
      </div>
      <Link
        to={`course-list`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Button
          variant="contained"
          color="primary"
          // size="small"
          //   onClick={}
          sx={{ textTransform: "none", marginTop: "20px", marginLeft: "2%" }}
        >
          Explore more courses
        </Button>
      </Link>
      <style jsx>{`
        .list-div {
          flex-wrap: wrap;
          justify-content: left;
        }
        .course-div {
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          min-width: 280px;
          max-width: 320px;
          overflow: hidden;
          margin-left: 2%;
        }
        .course-div:hover {
          background-color: #f4f4f4;
        }

        .image-div img {
          object-fit: cover;
        }
        .course-details {
          padding: 20px;
        }
        ul {
          padding-left: 17px;
          line-height: 25px;
        }
        .course-details img {
          border-radius: 50%;
          margin-left: 5px;
        }
        .duration-tag {
          border-radius: 5px;
          padding: 5px 8px;
          background-color: rgb(234 234 234);
        }
      `}</style>
    </div>
  );
};

export default Courses;
