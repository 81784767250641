import { Button } from "@mui/material";
import React from "react";
import { therapists } from "../../helper/data.helper";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { Link } from "react-router-dom";

const ChooseTherapist = () => {
  return (
    <div className="margin-top--lg">
      <h2 style={{ fontWeight: "normal", marginLeft: "2%" }}>
        COUNSELLING AND THERAPY
      </h2>
      <h2 className="margin-top--xs" style={{ marginLeft: "2%" }}>
        Find the Right Therapist and Start Getting Support
      </h2>
      <p style={{ color: "#611616", marginLeft: "2%" }}>
        Receive expert help from licensed psychologists and psychiatrists for
        stress, anxiety, depression, heartbreak, loneliness, relationship
        issues, sleep troubles, body image concerns, smoking cessation, trauma,
        and more. Our professional therapists provide personalized care to
        support your journey towards mental wellness.
      </p>
      <div className="flex-container  list-div">
        {therapists.slice(0, 3).map((therapist, index) => (
          <div key={therapist.id} className="therapist-div margin-top--lg">
            <Link
              to={`/homepage/therapist/${therapist.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="image-div">
                <img alt="profile" src={therapist.photo} />
              </div>
              <div className="flex-container justify-between">
                <h3>
                  {" "}
                  {therapist.name
                    ? therapist.name.length > 15
                      ? therapist.name.slice(0, 15) + "..."
                      : therapist.name
                    : null}
                </h3>
                <p className="margin-top--lg">
                  <VideocamOutlinedIcon
                    fontSize="small"
                    color="primary"
                    sx={{
                      backgroundColor: "rgb(216 230 253)",
                      padding: 0.2,
                      marginTop: "-5px",
                      borderRadius: "10%",
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <ChairOutlinedIcon
                    fontSize="small"
                    color="primary"
                    sx={{
                      backgroundColor: "rgb(216 230 253)",
                      padding: 0.2,
                      marginTop: "-5px",
                      borderRadius: "10%",
                    }}
                  />
                </p>
              </div>
              <p>
                <span
                  style={{
                    borderBottom: "1px solid lightgrey",
                    paddingBottom: "7px",
                    color: "black",
                  }}
                >
                  {therapist.title
                    ? therapist.title.length > 25
                      ? therapist.title.slice(0, 25) + "..."
                      : therapist.title
                    : null}
                </span>
              </p>
              <div className="margin-top--md">
                <div className="flex-container margin-top--sm">
                  <StarBorderIcon fontSize="small" color="primary" />
                  <p style={{ margin: "-1px 0px 0px 5px" }}>
                    {therapist.specialties
                      ? therapist.specialties.length > 30
                        ? therapist.specialties.slice(0, 30) + "..."
                        : therapist.specialties
                      : null}
                  </p>
                </div>
                <div className="flex-container margin-top--sm">
                  <PeopleAltOutlinedIcon fontSize="small" color="primary" />
                  <p style={{ margin: "-1px 0px 0px 5px" }}>
                    {therapist.worksWith
                      ? therapist.worksWith.length > 30
                        ? therapist.worksWith.slice(0, 30) + "..."
                        : therapist.worksWith
                      : null}
                  </p>
                </div>
                <div className="flex-container margin-top--sm">
                  <LanguageIcon fontSize="small" color="primary" />
                  <p style={{ margin: "-1px 0px 0px 5px" }}>
                    {therapist.languages
                      ? therapist.languages.length > 30
                        ? therapist.languages.slice(0, 30) + "..."
                        : therapist.languages
                      : null}
                  </p>
                </div>

                <p className="margin-top--sm">
                  Next Available: 26th June, 7:30 pm
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <Link
        to={`therapist-list`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Button
          variant="contained"
          color="primary"
          // size="small"
          //   onClick={}
          sx={{ textTransform: "none", marginTop: "20px", marginLeft: "2%" }}
        >
          Browse all therapist
        </Button>
      </Link>
      <style jsx>{`
        .list-div {
          flex-wrap: wrap;
          justify-content: left;
        }
        .therapist-div {
          padding: 20px;
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          margin-left: 2%;
          // min-width: 280px;
        }
        .therapist-div:hover {
          background-color: #f4f4f4;
        }
        .image-div {
          width: 150px;
          height: 150px;
          overflow: hidden;
          border-radius: 50%;
        }
        .image-div img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      `}</style>
    </div>
  );
};

export default ChooseTherapist;
