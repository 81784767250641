import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { auth } from "../../../helper/firebase.helper";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const timer = setInterval(() => {
      console.log(auth.currentUser?.emailVerified);
      if (
        auth.currentUser?.emailVerified
        //  &&
        // searchParams.get("email") === auth.currentUser?.email
      ) {
        const password = sessionStorage.getItem(auth.currentUser.email);
        auth
          .signInWithEmailAndPassword(auth.currentUser.email, password)
          .then(() => {
            toast.success("Email registered successfully!");
            navigate("/register/register-number");
            sessionStorage.removeItem(auth.currentUser.email);
          });
      } else {
        auth.currentUser?.reload();
      }
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="verify">
      <h4>You&apos;re almost there</h4>
      <p>
        Thanks for signing up! You would see a verification link in your email
        (it might be in your spam folder). Verify your email to access DeTalks.
      </p>
      <style jsx>{`
        .verify {
          text-align: center;
          padding: 5% 10% 10% 10%;
          min-height: 80vh;
        }
      `}</style>
    </div>
  );
};

export default VerifyEmail;
