import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { blogs } from "../../helper/data.helper";

const Blogs = () => {
  return (
    <div className="margin-top--xl">
      <h2 style={{ fontWeight: "normal", marginLeft: "2%" }}>BLOG</h2>
      <h2 className="margin-top--xs" style={{ marginLeft: "2%" }}>
        Educate Yourself for Mental Well-being
      </h2>
      <p style={{ color: "#611616", marginLeft: "2%" }}>
        Empower yourself with our curated collection of self-help tips on mental
        well-being. Our blog is your go-to resource for valuable insights and
        practical advice to enhance your mental health and overall well-being.
      </p>
      <div className="flex-container  list-div">
        {blogs.slice(0, 3).map((blog, index) => (
          <div key={blog.id} className="blog-div margin-top--lg">
            <Link
              //   to={`/therapist/${therapist.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="image-div">
                <img alt="blog-pic" src={blog.image} height={160} />
              </div>
              <div className="blog-details">
                <h3 style={{ marginTop: "-6px" }}>{blog.title}</h3>
                <p className="margin-top--sm">{blog.description}</p>
                <div className="flex-container justify-between margin-top--sm">
                  <div className="flex-container">
                    {blog.professorPics.map((pic, index) => (
                      <img
                        key={pic}
                        src={pic}
                        height={35}
                        alt="professor-pic"
                      />
                    ))}
                  </div>
                  <b className="duration-tag">{blog.duration}</b>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <Button
        // href=""
        variant="contained"
        color="primary"
        // size="small"
        //   onClick={}
        sx={{ textTransform: "none", marginTop: "20px", marginLeft: "2%" }}
      >
        Explore more blogs
      </Button>
      <style jsx>{`
        .list-div {
          flex-wrap: wrap;
          justify-content: left;
        }
        .blog-div {
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          min-width: 280px;
          max-width: 320px;
          overflow: hidden;
          margin-left: 2%;
        }
        .blog-div:hover {
          background-color: #f4f4f4;
        }

        .image-div img {
          object-fit: cover;
        }
        .blog-details {
          padding: 20px;
        }

        .blog-details img {
          border-radius: 50%;
          margin-left: 5px;
        }
        .duration-tag {
          border-radius: 5px;
          padding: 5px 8px;
          background-color: rgb(234 234 234);
        }
      `}</style>
    </div>
  );
};

export default Blogs;
